'use client';
import {CustomLink} from "@/data/types";
import React, {FC} from "react";
import twFocusClass from "@/lib/utils/twFocusClass";
import Link from "next/link";
import {defaultGearPerPage} from "@/lib/API.gears.const";
import {usePathname, useSearchParams} from "next/navigation";

export interface PaginationProps {
  className?: string;
  dataCount?: number;
  resultsPerPage?: number;
  handleChange?: (pageNumber: string) => void;
  page: string;
  queryString?: string;
}

const PaginationAlgolia: FC<PaginationProps> = (
  {
    className = "",
    dataCount = 0,
    resultsPerPage = defaultGearPerPage,
    page = '1',
  }
) => {
  const pageCount = dataCount === 0 ? 1 : Math.ceil(dataCount / resultsPerPage);
  const pagination: CustomLink[] = [...Array(pageCount)].map((_, index) => ({
    label: `${index + 1}`,
    href: "",
  }))
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const renderItem = (pag: CustomLink, index: number) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('page', `${index + 1}`)
    if (`${index + 1}` === page) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index + 1}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-green-600 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index + 1}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-neutral-600 hover:bg-green-800/50 border border-neutral-200 text-neutral-100 ${twFocusClass()}`}
        href={{pathname: pathname || 'wszystkie', query: newSearchParams.toString()}}
      >
        {pag.label}
      </Link>
    );
  };

  return (
    <nav
      className={`nc-Pagination flex flex-row flex-wrap items-center justify-center space-x-1 space-y-1 text-base font-medium ${className}`}
    >
      {/*{*/}
      {/*  pagination.length < 10*/}
      {/*    ? pagination.map(renderItem)*/}
      {/*    : pagination.reduce((acc: CustomLink[], item: CustomLink, index: number) => {*/}
      {/*      if (index > 10) {*/}
      {/*        return acc;*/}
      {/*      }*/}
      {/*      return [...acc, item]*/}
      {/*    }, [])*/}
      {/*      .map(renderItem)*/}
      {/*}*/}
      {pagination.map(renderItem)}
    </nav>
  );
};

export default PaginationAlgolia;
