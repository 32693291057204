'use client';

import {useRouter, useSearchParams, usePathname} from 'next/navigation';
import PriceFilter from './newSearchSideBar/price-filter';
import {useState} from 'react';
import SearchQueryInput from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/search-query-input";
import {TaxonomyType} from "@/data/types";
import LocationInputFilterStatic
  from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/location-input-filter-static";
import ManufacturerInputFilterStatic
  from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/manufacturer-input-filter-static";
import CategoryDropDown from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/category-drop-down";
import RentalifySortDropDown from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/sorting-select";
import VerifyLevelSelect from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/verify-level-select";
import ButtonNoStyle from "@/components/shared/Button/ButtonNoStyle";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {
  niceUrlDecoder,
  niceUrlEncoder
} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/utils/niceUrlEncoder";
import {
  parseDeprecatedSortSortBy
} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/utils/makeQueryString";

interface IDeprecatedFiltersSet {
  city?: string;
  manufacturer?: string;
  category?: string;
  page?: string;
}

interface FilterTypes {
  className?: string;
  searchQuery: string;
  cities: TaxonomyType[];
  manufacturers: TaxonomyType[];
  categories: TaxonomyType[];
  pricesRange: { priceMin: number, priceMax: number };
  oldParams?: IDeprecatedFiltersSet,
  mainUrl?: string,
  hideFilters?: {
    city?: boolean,
    price?: boolean,
    level?: boolean,
    sort?: boolean,
  }
}

export interface RentalifyListingFilterBasicProps {
  className?: string;
  searchQuery: string;
  updateSearchQuery: (
    instance: 'sorting' | 'level' | 'city' | 'price' | 'manufacturer' | 'category',
    query: any
  ) => void,
  triggerSearch?: () => void;
  oldParams?: IDeprecatedFiltersSet;
}

export interface RentalifyListingFilterSearchQueryProps extends RentalifyListingFilterBasicProps {
  updatePathname: (_searchQueryPathName: string) => void;
}

export interface RentalifyListingFilterLocation extends RentalifyListingFilterBasicProps {
  cities: TaxonomyType[];
}

export interface RentalifyListingFilterManufacturers extends RentalifyListingFilterBasicProps {
  manufacturers: TaxonomyType[];
}


export interface RentalifyListingFilterCategories extends RentalifyListingFilterBasicProps {
  categories: TaxonomyType[]
}

export interface RentalifyListingFilterPrices extends RentalifyListingFilterBasicProps {
  min: number,
  max: number,
  minDef: number,
  maxDef: number,
}

const checkPathnameIsDeprecated = (mainUrl: string, pathName: string): IDeprecatedFiltersSet & {
  isDeprecatedUrl: boolean
} => {
  pathName = pathName.includes(`${mainUrl}/`) ? pathName.split(`${mainUrl}/`)[1] : pathName;
  if (!pathName) {
    return {
      city: undefined,
      manufacturer: undefined,
      category: undefined,
      page: undefined,
      isDeprecatedUrl: false
    };
  }
  // const propsSearchQueryOldUrlToNewParse = /([a-zA-Z_]+)%2F([a-zA-Z_]+)%2F([a-zA-Z_]+)(%2F([0-9]+)?)?/gi.test(props.params.searchQuery);
  const searchQueryOldUrlToNewParse = /([a-zA-Z_]+)\/([a-zA-Z_]+)\/([a-zA-Z_]+)(\/([a-zA-Z0-9]+)?)?/gi.test(pathName);
  let deprecatedParams: IDeprecatedFiltersSet & { isDeprecatedUrl: boolean } = {
    city: undefined,
    manufacturer: undefined,
    category: undefined,
    page: undefined,
    isDeprecatedUrl: searchQueryOldUrlToNewParse
  }
  if (searchQueryOldUrlToNewParse) {
    const oldUrlToNewParseParams = pathName.split('/');
    deprecatedParams = {
      ...deprecatedParams,
      ...(oldUrlToNewParseParams[0] && oldUrlToNewParseParams[0]?.toLowerCase() !== 'wszystkie' ? {city: oldUrlToNewParseParams[0].toLowerCase()} : {}),
      ...(oldUrlToNewParseParams[1] && oldUrlToNewParseParams[1]?.toLowerCase() !== 'wszystkie' ? {category: oldUrlToNewParseParams[1].toLowerCase()} : {}),
      ...(oldUrlToNewParseParams[2] && oldUrlToNewParseParams[2]?.toLowerCase() !== 'wszystkie' ? {manufacturer: oldUrlToNewParseParams[2].toLowerCase()} : {}),
      ...(oldUrlToNewParseParams[3] ? {page: oldUrlToNewParseParams[3]} : {}),
    }
  }
  return deprecatedParams;
}

export default function RentalifyGearListingFilters(
  {
    className,
    searchQuery,
    cities,
    manufacturers,
    categories,
    pricesRange = {priceMin: 0, priceMax: 4000},
    oldParams,
    mainUrl = 'lista-sprzetu',
    hideFilters = {},
  }: FilterTypes) {
  const router = useRouter();
  const entryPathname = usePathname();
  const searchParams = useSearchParams();
  const [newSearchParams, setNewSearchParams] = useState<any>({
    // old params
    ...(oldParams?.city ? {city: oldParams?.city?.toLowerCase()} : {}),
    ...(oldParams?.manufacturer ? {manufacturer: oldParams?.manufacturer?.toLowerCase()} : {}),
    ...(oldParams?.category ? {category: oldParams?.category?.toLowerCase()} : {}),
    ...(searchParams.get('sort') ? {sort: searchParams.get('sort')} : {}),
    ...(searchParams.get('sortBy') ? {sortBy: searchParams.get('sortBy')} : {}),
    // new params
    ...(searchParams.get('sorting') ? {sorting: searchParams.get('sorting')} : {}),
    ...(searchParams.get('level') ? {level: searchParams.get('level')} : {}),
    ...(searchParams.get('city') ? {city: searchParams.get('city')?.toLowerCase()} : {}),
    ...(searchParams.get('priceMin') ? {priceMin: searchParams.get('priceMin')} : {}),
    ...(searchParams.get('priceMax') ? {priceMax: searchParams.get('priceMax')} : {}),
    ...(searchParams.get('manufacturer') ? {manufacturer: searchParams.get('manufacturer')?.toLowerCase()} : {}),
    ...(searchParams.get('category') ? {category: searchParams.get('category')?.toLowerCase()} : {})
  });
  const entrySearchQueryPathName = entryPathname.split(`/${mainUrl}/`);
  const [searchQueryPathName, setSearchQueryPathName] = useState<string | false>(niceUrlDecoder(entrySearchQueryPathName[1] || ''));
  const [queryChanged, setQueryChanged] = useState<boolean>(false);
  const hideCity = hideFilters?.city !== undefined ? hideFilters?.city : false;
  const hideSort = hideFilters?.sort !== undefined ? hideFilters?.sort : false;
  const hidePrice = hideFilters?.price !== undefined ? hideFilters?.price : false;
  const hideLevel = hideFilters?.level !== undefined ? hideFilters?.level : false;

  const handleUpdateSearchQuery = (instance: string, query: any) => {
    if (!query) {
      if (newSearchParams[instance]) {
        const newSearchParamsObj = {
          ...newSearchParams,
        };
        delete newSearchParamsObj[`${instance}`];
        setNewSearchParams(newSearchParamsObj)
        setQueryChanged(true);
      }
    } else {
      if (
        !newSearchParams[instance]
        || (newSearchParams[instance] && newSearchParams[instance] !== query)
      ) {
        const newSearchParamsObj = instance === 'price' ? {
          ...newSearchParams,
          [`priceMin`]: query.min,
          [`priceMax`]: query.max,
        } : {
          ...newSearchParams,
          [`${instance}`]: query?.toLowerCase(),
        };
        setNewSearchParams(newSearchParamsObj)
        setQueryChanged(true);
      }
    }
  }

  const handleUpdateSearchQueryPathName = (newSearchQueryPathName: string) => {
    if (searchQueryPathName !== newSearchQueryPathName) {
      setSearchQueryPathName(decodeURI(newSearchQueryPathName.length ? newSearchQueryPathName.trim() : ''));
      setQueryChanged(true);
    }
  }

  const handleTriggerSearch = () => {
    console.log('searchQueryPathName => ', searchQueryPathName);
    console.log('location.href => ', location.pathname);
    let testPathnameToParseParams: string | undefined = undefined;
    let newPathname = searchQueryPathName && searchQueryPathName?.length ? searchQueryPathName.trim() : '';
    if (location.pathname !== newPathname) {
      testPathnameToParseParams = location.pathname;
    }
    const paramsFromDeprecatedUrl = checkPathnameIsDeprecated(mainUrl, testPathnameToParseParams || newPathname)
    console.log('XXXXXXXXXXXX paramsFromDeprecatedUrl => ', paramsFromDeprecatedUrl);
    const newParamsKeys = Object.keys(newSearchParams);
    console.log('newParamsKeys => ', newParamsKeys);
    const url = new URL(location.href);
    ['sorting', 'level', 'city', 'priceMin', 'priceMax', 'manufacturer', 'category'].forEach(newParamsKey => {
      url.searchParams.delete(newParamsKey);
    })
    if (newParamsKeys.indexOf('sortBy') !== -1 && newParamsKeys.indexOf('sort') !== -1) {
      console.log('PARSE DEPR sort, sortBy => START');
      const {sort, sortBy} = parseDeprecatedSortSortBy(newSearchParams.sort, newSearchParams.sortBy);
      console.log('PARSE DEPR sort, sortBy => ', sort, sortBy);
      if (sort && sortBy) {
        console.log(`===== SET SORTING =====`);
        url.searchParams.set('sorting', `${sortBy}-${sort}`);
        url.searchParams.delete('sortBy');
        delete newSearchParams.sortBy;
        url.searchParams.delete('sort');
        delete newSearchParams.sort;
      }
    }
    if (newParamsKeys.length) {
      for (let newParamKey of newParamsKeys) {
        if (!newSearchParams[newParamKey]) {
          continue;
        }
        if (newParamKey === 'price') {
          url.searchParams.set('priceMin', newSearchParams[newParamKey]['min'].toString());
          url.searchParams.set('priceMax', newSearchParams[newParamKey]['max'].toString());
        } else {
          url.searchParams.set(newParamKey, newSearchParams[newParamKey].toString()?.toLowerCase());
        }
      }
    }
    if (paramsFromDeprecatedUrl.isDeprecatedUrl) {
      if (paramsFromDeprecatedUrl.city) {
        url.searchParams.set('city', paramsFromDeprecatedUrl.city.toLowerCase());
      }
      if (paramsFromDeprecatedUrl.manufacturer) {
        url.searchParams.set('manufacturer', paramsFromDeprecatedUrl.manufacturer.toLowerCase());
      }
      if (paramsFromDeprecatedUrl.category) {
        url.searchParams.set('category', paramsFromDeprecatedUrl.category.toLowerCase());
      }
      if (paramsFromDeprecatedUrl.page) {
        url.searchParams.set('page', paramsFromDeprecatedUrl.page);
      }
      newPathname = url.searchParams?.get('model') || '';
      url.searchParams.delete('model');
    }
    const queryString = url.searchParams.toString();
    setQueryChanged(false);
    const newUrl = `/${mainUrl}/${niceUrlEncoder(newPathname || '')}${queryString ? `?${queryString}` : ''}`;
    router.push(newUrl);
  }

  return (
    <>
      <div className={`lg:hidden flex flex-row align-bottom items-end justify-end`}>
        <SearchQueryInput
          className={`mb-2 mt-3 w-2/3`}
          searchQuery={searchQuery}
          updateSearchQuery={handleUpdateSearchQuery}
          updatePathname={handleUpdateSearchQueryPathName}
          triggerSearch={handleTriggerSearch}
        />
        <ButtonNoStyle
          onClick={handleTriggerSearch}
          className={
            ` flex items-end align-bottom
              !rounded-2xl !h-11 !py-1 w-1/3 mb-1.5 ml-2 ${
              !queryChanged
                ? `cursor-default bg-neutral-600 hover:bg-neutral-600`
                : `cursor-pointer bg-green-800 hover:bg-green-700`
            }`
          }
          disabled={!queryChanged}
        >
          <ArrowPathIcon width={18} height={18}/>
          <span className={'ml-2'}>Filtruj</span>
        </ButtonNoStyle>
      </div>
      <div className={`grid grid-cols-2 gap-2 lg:gap-4 lg:grid-cols-1 lg:mt-8 ${className}`}>
        <SearchQueryInput
          className={`lgm:hidden lgm:mb-2 lgm:mt-3`}
          searchQuery={searchQuery}
          updateSearchQuery={handleUpdateSearchQuery}
          updatePathname={handleUpdateSearchQueryPathName}
          triggerSearch={handleTriggerSearch}
        />
        <ButtonNoStyle
          onClick={handleTriggerSearch}
          className={
            `lgm:hidden !rounded-2xl ${!queryChanged
              ? `cursor-default bg-neutral-600 hover:bg-neutral-600`
              : `cursor-pointer bg-green-800 hover:bg-green-700`}`
          }
          disabled={!queryChanged}
        >
          <ArrowPathIcon width={18} height={18}/>
          <span className={'ml-2'}>Odśwież wyniki</span>
        </ButtonNoStyle>
        {
          hideSort
            ? null
            : <RentalifySortDropDown
              searchQuery={searchQuery}
              updateSearchQuery={handleUpdateSearchQuery}
            />
        }
        {
          hideLevel ? null
            : <VerifyLevelSelect
              searchQuery={searchQuery}
              updateSearchQuery={handleUpdateSearchQuery}
            />
        }
        {
          hideCity ? null
            : <LocationInputFilterStatic
              searchQuery={searchQuery}
              cities={cities}
              updateSearchQuery={handleUpdateSearchQuery}
              triggerSearch={handleTriggerSearch}
              oldParams={oldParams}
            />
        }
        {
          hidePrice ? null
            : <PriceFilter
              searchQuery={searchQuery}
              min={pricesRange.priceMin}
              max={pricesRange.priceMax}
              minDef={pricesRange.priceMin}
              maxDef={pricesRange.priceMax}
              updateSearchQuery={handleUpdateSearchQuery}
            />
        }
        <ManufacturerInputFilterStatic
          searchQuery={searchQuery}
          manufacturers={manufacturers}
          updateSearchQuery={handleUpdateSearchQuery}
          triggerSearch={handleTriggerSearch}
          oldParams={oldParams}
        />
        <CategoryDropDown
          searchQuery={searchQuery}
          categories={categories}
          updateSearchQuery={handleUpdateSearchQuery}
          oldParams={oldParams}
        />

        {/*<Manufacturer searchQuery={searchQuery} manufacturers={manufacturers}/>*/}
        {/*<CategoryFilter searchQuery={searchQuery} categories={categories}/>*/}
        {/*<WithAssistantFilter searchQuery={searchQuery}/>*/}
        {/*<WithSendSendFilter searchQuery={searchQuery}/>*/}
        {/*<TripdateFilter searchQuery={searchQuery}/>*/}
        {/*<BoatTypeFilter searchQuery={searchQuery}/>*/}
        {/*<DistanceFilter searchQuery={searchQuery}/>*/}
        {/*<GuestFilter searchQuery={searchQuery}/>*/}
        {/*<FreeCancellation searchQuery={searchQuery}/>*/}

      </div>
      <div>

      </div>
    </>
  );
}
